const About = () => {
  return (
    <div className="about-page">
      <h1>Welcome To DigiX Radios</h1>
      <p className="about">
        Your trusted partner for cutting-edge wireless communication and two-way
        radio solutions in the MiddleEast & Africa region. Established with a
        passion for seamless communication and a commitment to quality, we have
        become the go-to destination for individuals, businesses, and
        organizations seeking reliable and efficient communication tools. With a
        management team with over two decades of experience in providing
        communications and wireless solutions to clients, DigiX Telecom has been
        formed to provide solutions and services to support the communication
        needs of Middle East and Africa region businesses. Headquartered in
        Ajman, United Arab Emirates, DigiX Telecom offers a wide range of
        Two-way radios and wireless communication products that are perfect for
        a variety of uses, including business, construction, hospitality, and
        outdoor recreation. Our comprehensive range of offerings includes:
        Two-Way Radio: Wireless 446/409 Licensed VHF and UHF, Mobile radios,
        Base Stations, Repeaters, Duplexer, Out door and car antennas.
      </p>
    </div>
  );
};
export default About;
