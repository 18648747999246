import Hero from "../components/Hero";
import Brands from "../sections/Brands";

export default function Home() {
  return (
    <div>
      <Hero />
      <section className="slogan">
        <p>Your quest for radio communication solution ends here!!</p>
      </section>
      <Brands />
    </div>
  );
}
