//Node Modules
import { useLocation } from "react-router-dom";

//Project Files
import data from "../data.json";
import CategoryCard from "../components/CategoryCard";
import { useEffect } from "react";
import coming_soon from "../assets/coming-soon.png";

export default function Products() {
  const location = useLocation();
  const selectedBrand = location.state?.brand;
  const brandData = data.products.find((item) => item.brand === selectedBrand);
  const categories = brandData?.types;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return categories ? (
    <div className="categories-page">
      <h1>{selectedBrand}</h1>
      <p>Choose your product category</p>
      <section className="categories-section">
        {categories.map((category) => (
          <CategoryCard
            key={category}
            category={category}
            brandData={brandData}
          />
        ))}
      </section>
    </div>
  ) : (
    <div className="categories-page">
      <img className="coming-soon" src={coming_soon} alt="Coming soon" />
    </div>
  );
}
