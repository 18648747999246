//Node Modules
import { useNavigate } from "react-router-dom";

export default function CategoryCard({ category, brandData }) {
  const requiredData = brandData[category];
  const navigate = useNavigate();
  function handleViewProducts() {
    navigate("/products", { state: { productData: requiredData } });
  }
  return (
    <button className="category-card" onClick={() => handleViewProducts()}>
      <img src={`../assets/${requiredData.thumbnailURL}`} alt={category} />
      <div className="navigation-section">
        <h1>{category}</h1>
        <span>View range of products</span>
      </div>
    </button>
  );
}
