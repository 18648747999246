//Node Modules
import { useNavigate } from "react-router-dom";

export default function BrandCard({ logo, brand }) {
  const navigate = useNavigate();
  function handleViewProducts() {
    navigate("/categories", { state: { brand: brand } });
  }
  return (
    <article className="brand-card">
      <img className={brand} src={logo} alt="logo" />
      <button
        onClick={() => {
          handleViewProducts();
        }}
      >
        View Products
      </button>
    </article>
  );
}
