import emailjs from "@emailjs/browser";

export function sendEmail(e) {
  e.preventDefault();
  emailjs
    .sendForm(
      "service_lyiqa2d",
      "template_aeror6q",
      e.target,
      "zZPdfzLErpocDhhQI"
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
}
