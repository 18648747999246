import { Routes, Route } from "react-router-dom";

/*Project Files*/
import "./styles/styles.css";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Categories from "./pages/Categories";
import Products from "./pages/Products";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/products" element={<Products />} />

        <Route path="*" element={<div>This page does not exist!!</div>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
