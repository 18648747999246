//Node Modules
import { Link } from "react-router-dom";

//Project Files
import logo from "../assets/logo.png";

export default function Navbar() {
  return (
    <nav className="navbar">
      <Link to="/about">ABOUT US</Link>
      <Link className="logo-holder" to="/">
        <img className="logo" src={logo} alt="Logo of Digix" />
        <span>Global Communication Solutions</span>
      </Link>
      <Link to="/contact">CONTACT US</Link>
    </nav>
  );
}
