//Node Modules
import React, { useState, useRef, useEffect } from "react";
import { sendEmail } from "../scripts/sendEmail";
import whatsapp from "../assets/whatsapp.png";

export default function Form() {
  const [success, setSuccess] = useState(false);
  const form = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    sendEmail(e);
    setSuccess(true);
    e.target.reset();
  }

  return (
    <div className="contact-page">
      <h1 className="contact-invite">
        If you would like to know more, or would just like a friendly
        {" (no obligation)"} chat of what we can do for you, please get in touch
        with us.
      </h1>
      <form
        ref={form}
        className="contact-form flex-column-center"
        onSubmit={(e) => handleSubmit(e)}
      >
        <h2>CONTACT US</h2>
        <div className="label">
          <span>Name</span>
          <span className="red">*</span>
        </div>
        <input
          type="text"
          name="name"
          required
          placeholder="Example: John Doe"
        />
        <div className="label">
          <span>Company</span>
          <span className="red">*</span>
        </div>
        <input
          type="text"
          name="company"
          required
          placeholder="Example: Digix"
        />
        <div className="label">
          <span>Email</span>
          <span className="red">*</span>
        </div>
        <input
          type="email"
          name="email"
          required
          placeholder="Example: johndoe@mail.com"
        />
        <div className="label">
          <span>Phone</span>
          <span className="red">*</span>
        </div>
        <input
          type="text"
          name="mobile"
          required
          placeholder="Example: +12 0123456781"
        />
        <div className="label">
          <span>Message</span>
          <span className="red">*</span>
        </div>
        <textarea
          type="text"
          name="message"
          required
          placeholder="Please leave a short message about your requirements."
        ></textarea>
        {success && (
          <span className="success-message">
            Thank you for contacting us. We have received your query. We will
            get back to you soon!!.
          </span>
        )}
        <input type="submit" />
      </form>
      <section className="whatsapp-section">
        <h2>Chat with us</h2>
        <a href="https://wa.me/971505456785">
          <img className="whatsapp" src={whatsapp} alt="An icon of whatsapp" />
        </a>
      </section>
      <section className="address-section">
        <h2>Address</h2>
        <p>DigiX Telecom FZC LLC, </p>
        <p>Ajman Media City Free Zone,</p>
        <p>Ajman Boulevard,</p>
        <p>Commercial Building A,</p>
        <p> 6th Floor,</p>
        <p> Ajman</p>
      </section>
      <iframe
        className="map"
        title="location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3604.0054549356687!2d55.4835772!3d25.404628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef5f925866c326b%3A0x2c398d47d0c3e390!2sAJMAN%20MEDIA%20CITY%20FREEZONE%20(HEAD%20OFFICE)!5e0!3m2!1sen!2sae!4v1707732589091!5m2!1sen!2sae"
        width="600"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <section className="hours-section">
        <h2>Working hours</h2>
        <p>Monday-Thursday : 9:00 to 5:00</p>
        <p>Friday: 9:00 to 1:00</p>
        <p>Saturday: 9:00 to 5:00</p>
        <p>The store is closed on all Sundays</p>
      </section>
    </div>
  );
}
