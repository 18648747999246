import BrandCard from "../components/BrandCard";
import motorola from "../assets/motorola.png";
import standard_horizon from "../assets/standard_horizon.png";
import icom from "../assets/icom.png";
import iridium from "../assets/iridium.jpg";
import kenwood from "../assets/kenwood.png";
import thuraya from "../assets/thuraya.jpg";
import yaesu from "../assets/yaesu.png";

export default function Brands() {
  return (
    <section className="brands-section">
      <h2>OUR BRANDS</h2>
      <section className="brands">
        <BrandCard logo={motorola} brand={"motorola"} />
        <BrandCard logo={standard_horizon} brand={"standard horizon"} />
        <BrandCard logo={icom} brand={"icom"} />
        <BrandCard logo={iridium} brand={"iridium"} />
        <BrandCard logo={kenwood} brand={"kenwood"} />
        <BrandCard logo={thuraya} brand={"thuraya"} />
        <BrandCard logo={yaesu} brand={"yaesu"} />
      </section>
    </section>
  );
}
