//Node Modules
import { useNavigate } from "react-router-dom";

import hero from "../assets/hero.jpg";

export default function Hero() {
  const navigate = useNavigate();
  function handleGetQuote() {
    navigate("/contact");
  }
  return (
    <main className="hero">
      <section className="quote-link-section">
        <button onClick={() => handleGetQuote()}>GET A QUOTE</button>
      </section>
      <section className="hero-image-section">
        <img className="hero-image" src={hero} alt="Some walkie talkies" />
      </section>
    </main>
  );
}
